
const alert = ($store, {title, msg, btn}) => {

  let defaultTitle = "경고";

  let defaultBtn = {
    name:"닫기",
  }

  let data = {
    state:true,
    type: "alert",
    title: title ? title : defaultTitle,
    msg: "<p>"+msg+"</p>",
    btn: btn ? btn : defaultBtn
  };
  $store.dispatch('ui/setPopAlertData', data);
};

const closeAlert = $store => {
  if ($store.state.ui.popAlertData.state) {
    let data = {
      state: false,
      type: null,
      title: null,
      msg: null,
      btn: {
        link: '',
        name: null,
        fnName: null,
      },
    };
    $store.dispatch('ui/setPopAlertData', data);
  }
};

const confirm = ($store, { id, title, msg, btn }) => {
  let data = {
    state: true,
    id: id,
    title: title ? title : 'Confirm',
    msg: '<p>' + msg + '</p>',
    btn: { name: btn ? btn : 'save' },
  };
  $store.dispatch('ui/setPopConfirmData', data);
};

export default {
  alert,
  closeAlert,
  confirm
};
