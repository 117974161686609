import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/commonOpenIp/prdc';

const getPrdc = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios
    .get(`${baseUrl}?${queryParam}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const getPrdcDetail = (patnNo, axios) => {
  return axios
    .get(`${baseUrl}/${patnNo}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const addPrdc = (params, axios) => {
  const formData = new FormData();
  for (let key in params) {
    if (params[key] != null && params[key] != '') formData.append(key, params[key]);
  }
  return axios
    .post(baseUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const updatePrdc = (params, axios) => {
  const formData = new FormData();
  for (let key in params) {
    if (params[key] != null && params[key] != '') formData.append(key, params[key]);
  }
  return axios
    .put(baseUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const addPrtf = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios
    .post(`${baseUrl}/prtf?${queryParam}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

export default {
  getPrdc,
  getPrdcDetail,
  addPrdc,
  updatePrdc,
  addPrtf,
};
