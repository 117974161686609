import sectorService from '~/service/commonTenbagger/sectorService';
import themeService from '~/service/commonTenbagger/themeService';
import mtsService from '~/service/commonTenbagger/mtsService';
import stkService from '~/service/commonTenbagger/stkService';

export const state = () => ({
  sectorList: [],
  themeList: [],
  mtsIssue: {},
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchSectorList({ commit }) {
    return sectorService
      .getSectorList(this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          let sectors = data.contents.map(el => ({ name: el.sectorName, value: el.sectorNo }));
          commit('UPDATE_STATE', { sectorList: sectors });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchThemeList({ commit }) {
    return themeService
      .getThemeList(this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          let themes = data.contents.map(el => ({ name: el.themeName, value: el.themeNo }));
          commit('UPDATE_STATE', { themeList: themes });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchMtsIssue({ commit }) {
    return mtsService
      .getMtsIssue('SK', this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          commit('UPDATE_STATE', { mtsIssue: data.contents });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchIssueSectorStk(_, payload) {
    return stkService
      .getIssueSectorStk(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  addIssueSectorStk(_, payload) {
    return sectorService
      .addIssueSectorStk(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateIssueSector(_, payload) {
    return mtsService
      .updateMtsIssueSector(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateIssueTheme(_, payload) {
    return mtsService
      .updateMtsIssueTheme(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateIssueDt(_, issueDt) {
    return mtsService
      .updateMtsIssueDt(issueDt, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },
};
