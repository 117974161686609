import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0132fbd0 = () => interopDefault(import('../pages/authTest.vue' /* webpackChunkName: "pages/authTest" */))
const _5f87a72e = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _8bb5a044 = () => interopDefault(import('../pages/index2.vue' /* webpackChunkName: "pages/index2" */))
const _0c4c378d = () => interopDefault(import('../pages/joinStep1.vue' /* webpackChunkName: "pages/joinStep1" */))
const _0c5a4f0e = () => interopDefault(import('../pages/joinStep2.vue' /* webpackChunkName: "pages/joinStep2" */))
const _74d15d1b = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _11ba7de0 = () => interopDefault(import('../pages/mobile.vue' /* webpackChunkName: "pages/mobile" */))
const _fe04eb80 = () => interopDefault(import('../pages/thinkpool.vue' /* webpackChunkName: "pages/thinkpool" */))
const _546d4f56 = () => interopDefault(import('../pages/allOk/customers.vue' /* webpackChunkName: "pages/allOk/customers" */))
const _12c007b7 = () => interopDefault(import('../pages/customer/report.vue' /* webpackChunkName: "pages/customer/report" */))
const _38815013 = () => interopDefault(import('../pages/homepage/article.vue' /* webpackChunkName: "pages/homepage/article" */))
const _445c456a = () => interopDefault(import('../pages/homepage/notice.vue' /* webpackChunkName: "pages/homepage/notice" */))
const _28a77d63 = () => interopDefault(import('../pages/homepage/urnews.vue' /* webpackChunkName: "pages/homepage/urnews" */))
const _0dcb8324 = () => interopDefault(import('../pages/openip/order.vue' /* webpackChunkName: "pages/openip/order" */))
const _7e367510 = () => interopDefault(import('../pages/openip/patent.vue' /* webpackChunkName: "pages/openip/patent" */))
const _bf3fece2 = () => interopDefault(import('../pages/openip/product.vue' /* webpackChunkName: "pages/openip/product" */))
const _68b5d9a7 = () => interopDefault(import('../pages/sample/cs.vue' /* webpackChunkName: "pages/sample/cs" */))
const _3e565c86 = () => interopDefault(import('../pages/sample/grid.vue' /* webpackChunkName: "pages/sample/grid" */))
const _39eb26c6 = () => interopDefault(import('../pages/sample/grid3.vue' /* webpackChunkName: "pages/sample/grid3" */))
const _0119dc86 = () => interopDefault(import('../pages/sample/page.vue' /* webpackChunkName: "pages/sample/page" */))
const _1ee517e4 = () => interopDefault(import('../pages/tenbagger/hmsecmts.vue' /* webpackChunkName: "pages/tenbagger/hmsecmts" */))
const _60a2d437 = () => interopDefault(import('../pages/tenbagger/news.vue' /* webpackChunkName: "pages/tenbagger/news" */))
const _40853b04 = () => interopDefault(import('../pages/tenbagger/nhmts.vue' /* webpackChunkName: "pages/tenbagger/nhmts" */))
const _0e8f3c4a = () => interopDefault(import('../pages/tenbagger/sector.vue' /* webpackChunkName: "pages/tenbagger/sector" */))
const _0691d7c0 = () => interopDefault(import('../pages/tenbagger/skmts.vue' /* webpackChunkName: "pages/tenbagger/skmts" */))
const _200a003c = () => interopDefault(import('../pages/tenbagger/stock.vue' /* webpackChunkName: "pages/tenbagger/stock" */))
const _b52225d6 = () => interopDefault(import('../pages/tenbagger/theme.vue' /* webpackChunkName: "pages/tenbagger/theme" */))
const _58cd66da = () => interopDefault(import('../pages/tenbagger/thinkpool.vue' /* webpackChunkName: "pages/tenbagger/thinkpool" */))
const _a9ac8bf8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/authTest",
    component: _0132fbd0,
    name: "authTest"
  }, {
    path: "/guide",
    component: _5f87a72e,
    name: "guide"
  }, {
    path: "/index2",
    component: _8bb5a044,
    name: "index2"
  }, {
    path: "/joinStep1",
    component: _0c4c378d,
    name: "joinStep1"
  }, {
    path: "/joinStep2",
    component: _0c5a4f0e,
    name: "joinStep2"
  }, {
    path: "/login",
    component: _74d15d1b,
    name: "login"
  }, {
    path: "/mobile",
    component: _11ba7de0,
    name: "mobile"
  }, {
    path: "/thinkpool",
    component: _fe04eb80,
    name: "thinkpool"
  }, {
    path: "/allOk/customers",
    component: _546d4f56,
    name: "allOk-customers"
  }, {
    path: "/customer/report",
    component: _12c007b7,
    name: "customer-report"
  }, {
    path: "/homepage/article",
    component: _38815013,
    name: "homepage-article"
  }, {
    path: "/homepage/notice",
    component: _445c456a,
    name: "homepage-notice"
  }, {
    path: "/homepage/urnews",
    component: _28a77d63,
    name: "homepage-urnews"
  }, {
    path: "/openip/order",
    component: _0dcb8324,
    name: "openip-order"
  }, {
    path: "/openip/patent",
    component: _7e367510,
    name: "openip-patent"
  }, {
    path: "/openip/product",
    component: _bf3fece2,
    name: "openip-product"
  }, {
    path: "/sample/cs",
    component: _68b5d9a7,
    name: "sample-cs"
  }, {
    path: "/sample/grid",
    component: _3e565c86,
    name: "sample-grid"
  }, {
    path: "/sample/grid3",
    component: _39eb26c6,
    name: "sample-grid3"
  }, {
    path: "/sample/page",
    component: _0119dc86,
    name: "sample-page"
  }, {
    path: "/tenbagger/hmsecmts",
    component: _1ee517e4,
    name: "tenbagger-hmsecmts"
  }, {
    path: "/tenbagger/news",
    component: _60a2d437,
    name: "tenbagger-news"
  }, {
    path: "/tenbagger/nhmts",
    component: _40853b04,
    name: "tenbagger-nhmts"
  }, {
    path: "/tenbagger/sector",
    component: _0e8f3c4a,
    name: "tenbagger-sector"
  }, {
    path: "/tenbagger/skmts",
    component: _0691d7c0,
    name: "tenbagger-skmts"
  }, {
    path: "/tenbagger/stock",
    component: _200a003c,
    name: "tenbagger-stock"
  }, {
    path: "/tenbagger/theme",
    component: _b52225d6,
    name: "tenbagger-theme"
  }, {
    path: "/tenbagger/thinkpool",
    component: _58cd66da,
    name: "tenbagger-thinkpool"
  }, {
    path: "/",
    component: _a9ac8bf8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
