import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/tenbagger/stk';

const getStk = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.get(`${baseUrl}?${queryParam}`).then(response => {
    return response.data;
  });
};

const getStkDetail = (isuSrtCd, axios) => {
  return axios.get(`${baseUrl}/${isuSrtCd}`).then(response => {
    return response.data;
  });
};

const getIssueSectorStk = ({ sectorNo, form }, axios) => {
  const queryParam = generateQueryParameter(form);
  return axios.get(`${baseUrl}/issue/sector/${sectorNo}?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateStkOfrPrc = ({ isuSrtCd, ofrPrc }, axios) => {
  return axios.put(`${baseUrl}/${isuSrtCd}/ofr-prc?ofrPrc=${ofrPrc}`).then(response => {
    return response.data;
  });
};

export default {
  getStk,
  getStkDetail,
  getIssueSectorStk,
  updateStkOfrPrc,
};
