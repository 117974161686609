const baseUrl = '/api/commonOpenIp/codes';

const getCodes = axios => {
  return axios
    .get(baseUrl)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

export default {
  getCodes,
};
