import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/tenbagger/mts';

const getMtsIssue = (securityType, axios) => {
  return axios.get(`${baseUrl}/issue?securityType=${securityType}`).then(response => {
    return response.data;
  });
};

const updateMtsIssueSector = (params, axios) => {
  let queryParam = generateQueryParameter(params);
  return axios.put(`${baseUrl}/issueSector?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateMtsIssueTheme = (params, axios) => {
  let queryParam = generateQueryParameter(params);
  return axios.put(`${baseUrl}/issueTheme?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateMtsIssueDt = (issueDt, axios) => {
  return axios.put(`${baseUrl}/issueDt?issueDt=${issueDt}`).then(response => {
    return response.data;
  });
};

const updateMtsDefaultSector = (sectorNo, axios) => {
  return axios.put(`${baseUrl}/defaultSector?sectorNo=${sectorNo}`).then(response => {
    return response.data;
  });
};

const updateMtsDefaultTheme = (themeNo, axios) => {
  return axios.put(`${baseUrl}/defaultTheme?themeNo=${themeNo}`).then(response => {
    return response.data;
  });
};

export default {
  getMtsIssue,
  updateMtsIssueSector,
  updateMtsIssueTheme,
  updateMtsIssueDt,
  updateMtsDefaultSector,
  updateMtsDefaultTheme,
};
