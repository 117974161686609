import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/tenbagger/sector';

const getSector = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.get(`${baseUrl}/list?${queryParam}`).then(response => {
    return response.data;
  });
};

const getSectorList = axios => {
  return axios.get(baseUrl).then(response => {
    return response.data;
  });
};

const getSectorDetail = (sectorNo, axios) => {
  return axios.get(`${baseUrl}/${sectorNo}`).then(response => {
    return response.data;
  });
};

const addSector = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.post(`${baseUrl}?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateSector = ({ sectorNo, form }, axios) => {
  const queryParam = generateQueryParameter(form);
  return axios.put(`${baseUrl}/${sectorNo}?${queryParam}`).then(response => {
    return response.data;
  });
};

const deleteSector = (sectorNo, axios) => {
  return axios.delete(`${baseUrl}/${sectorNo}`).then(response => {
    return response.data;
  });
};

const addIssueSectorStk = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.post(`${baseUrl}/issue/stk?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateIssueSector = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/issue?${queryParam}`).then(response => {
    return response.data;
  });
};

export default {
  getSector,
  getSectorList,
  getSectorDetail,
  addSector,
  updateSector,
  deleteSector,
  addIssueSectorStk,
  updateIssueSector,
};
