import validator from './validationPlugin';
import popup from './popupPlugin';
import roleCheck from './roleCheckPlugins';
import {generateQueryParameter} from '../util/HttpUtils';

export default (context, inject) => {
  const { router, store } = context;

  inject('roleCheck', (alowedRoles) => {
    if(process.client)
      return roleCheck(store, alowedRoles);
    return false;
  });

  inject('validate', validator);

  inject('popupAlert', data => {
    console.log('popalert',data)
    popup.alert(store, data);
  });

  inject('closeAlert', () => {
    const { store } = context;
    popup.closeAlert(store);
  });

  inject('popupConfirm', data => {
    popup.confirm(store, data);
  });

  inject('generateQueryParam', params => {
    return generateQueryParameter(params);
  });

  inject('fill0', number => {
    return number >= 10 ? number : '0'+number;
  });

  inject('dpPatnPrice', (technicalAsset) => {
    if(technicalAsset){
      if(technicalAsset === 0)
        return '0원';
      const flooredAsset = Math.floor(technicalAsset/100000000);
      if(flooredAsset>0){
        return `${flooredAsset.toLocaleString()}억원 대`
      }else{
        return '1억원 미만'
      }
    }
    return '-';
  });
};
