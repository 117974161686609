export default (store, allowedRoles) => {
  if(store.state.authUser){
    const userRoles = store.state.authUser.roles;
    if(userRoles){
      const find = userRoles.find( ur => {
        return allowedRoles.includes(ur);
      });
      return find ? true : false;
    }
  }
  return false;
}
