import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/openIp/order';

const getOrder = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios
    .get(`${baseUrl}?${queryParam}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const getOrderDetail = (orderId, axios) => {
  return axios
    .get(`${baseUrl}/${orderId}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const updateOrderStateCd = (orderId, stateCd, axios) => {
  return axios
    .put(`${baseUrl}/${orderId}?state_cd=${stateCd}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

export default {
  getOrder,
  getOrderDetail,
  updateOrderStateCd,
};
