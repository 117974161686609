import productService from '~/service/commonOpenIp/productService';

export const state = () => ({
  prdcList: null,
  productDetail: null,
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  addProduct(_, body) {
    return productService
      .postProduct(body, this.$axios)
      .then(status => {
        return status === 204 ? true : false;
      })
      .catch(err => {
        return false;
      });
  },
  fetchProductList({ state, commit }) {
    return productService.getProduct(state.searchForm, this.$axios).then(data => {
      commit('UPDATE_STATE', { prdcList: data.prdcs });
    });
  },
  fetchProductDetail({ commit }, prdc) {
    return productService.getProductDetail(prdc, this.$axios).then(data => {
      commit('UPDATE_STATE', { productDtail: data });
    });
  },
};
