import patnService from '~/service/commonOpenIp/patnService';

export const state = () => ({});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchPatn(_, form) {
    return patnService.getPatn(form, this.$axios).then(data => {
      return data;
    });
  },

  fetchPatnDetail(_, patnNo) {
    return patnService.getPatnDetail(patnNo, this.$axios).then(data => {
      return data;
    });
  },
};
