import prdcService from '~/service/commonOpenIp/prdcService';

export const state = () => ({});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchPrdc(_, form) {
    return prdcService.getPrdc(form, this.$axios).then(data => {
      return data;
    });
  },

  fetchPrdcDetail(_, prdcNo) {
    return prdcService.getPrdcDetail(prdcNo, this.$axios).then(data => {
      return data;
    });
  },

  addPrdc(_, form) {
    return prdcService.addPrdc(form, this.$axios).then(data => {
      return data;
    });
  },

  updatePrdc(_, form) {
    return prdcService.updatePrdc(form, this.$axios).then(data => {
      return data;
    });
  },

  addPrtf(_, form) {
    return prdcService.addPrtf(form, this.$axios).then(data => {
      return data;
    });
  },
};
