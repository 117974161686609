import catgService from '~/service/commonOpenIp/catgService';

export const state = () => ({
  category: {
    industry: [],
    theme: [],
  },
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  async fetchCatg({ commit }) {
    let { result, data } = await catgService.getCatg(this.$axios);
    if (result) {
      commit('UPDATE_STATE', { category: data.contents });
    } else {
      console.log(data.error.msg);
    }
  },

  addCatgPrdc(_, form) {
    return catgService.addCatgPrdc(form, this.$axios).then(data => {
      return data;
    });
  },
};
