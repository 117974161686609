import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/commonOpenIp/catg';

const getCatg = async axios => {
  return axios
    .get(baseUrl)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const addCatgPrdc = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios
    .post(`${baseUrl}/prdc?${queryParam}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

export default {
  getCatg,
  addCatgPrdc,
};
