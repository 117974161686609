import stkService from '~/service/commonTenbagger/stkService';
import sectorService from '~/service/commonTenbagger/sectorService';
import themeService from '~/service/commonTenbagger/themeService';
import corpService from '~/service/commonTenbagger/corpService';

export const state = () => ({
  sectorList: [],
  themeList: [],
  mktCd: [
    {
      code: '11',
      codeName: 'KOSPI',
    },
    {
      code: '12',
      codeName: 'KOSDAQ',
    },
    {
      code: '13',
      codeName: 'K-OTC',
    },
    {
      code: '14',
      codeName: 'KONEX',
    },
    {
      code: '15',
      codeName: 'ETC',
    },
  ],
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchStk(_, form) {
    return stkService
      .getStk(form, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchStkDetail(_, isuSrtCd) {
    return stkService
      .getStkDetail(isuSrtCd, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchSectorList({ commit }) {
    return sectorService
      .getSectorList(this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          let sectors = data.contents.map(el => ({ name: el.sectorName, value: el.sectorNo }));
          commit('UPDATE_STATE', { sectorList: sectors });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchThemeList({ commit }) {
    return themeService
      .getThemeList(this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          let themes = data.contents.map(el => ({ name: el.themeName, value: el.themeNo }));
          commit('UPDATE_STATE', { themeList: themes });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateCorpRprSector(_, payload) {
    return corpService
      .updateCorpRprSector(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateCorpRprTheme(_, payload) {
    return corpService
      .updateCorpRprTheme(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  addCorpTheme(_, payload) {
    return corpService
      .addCorpTheme(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateStkOfrPrc(_, payload) {
    return stkService
      .updateStkOfrPrc(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },
};
