import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/tenbagger/news';

const getNewsConf = axios => {
  return axios.get(`${baseUrl}/conf`).then(response => {
    return response.data;
  });
};

const updateNewsConf = (query, axios) => {
  let queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/conf?${queryParam}`).then(response => {
    return response.data;
  });
};

export default {
  getNewsConf,
  updateNewsConf,
};
