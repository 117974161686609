import orderService from '~/service/openIp/orderService';

export const state = () => ({});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchOrder(_, form) {
    return orderService.getOrder(form, this.$axios).then(data => {
      return data;
    });
  },

  fetchOrderDetail(_, orderId) {
    return orderService.getOrderDetail(orderId, this.$axios).then(data => {
      return data;
    });
  },
  updateOrderStateCd(_, payload) {
    return orderService.updateOrderStateCd(payload.orderId, payload.stateCd, this.$axios).then(data => {
      return data;
    });
  },
};
