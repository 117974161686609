import customersService from '~/service/allOk/customersService';

export const state = () => ({
  customerId: null,
  customerName:null,
  cnsltId: null,
  companyInfo: null,
});

export const mutations = {
  UPDATE_CNSLT_ID(state, cnsltId){
    state.cnsltId = cnsltId;
  },
  UPDATE_CUSTOMER_ID(state, customerId) {
    state.customerId = customerId;
  },
  UPDATE_CUSTOMER_NAME(state, customerName) {
    state.customerName = customerName;
  },
  UPDATE_COMPANYINFO(state, payload) {
    state.companyInfo = payload
  }
};

export const actions = {
  fetchCompanyInfo({commit},params){
    return customersService.getCompanyInfo(params, this.$axios).then(data => {
      commit('UPDATE_COMPANYINFO', data.data.contents);
      return data.data.contents
    });
  },
}

export const getters = {
  productType(state) {
    return {
      tcb: isCertHolder(state.companyInfo) || isTechHolder(state.companyInfo) || isAllowedSector(state.companyInfo),
      org: true,
      ip: isTechHolder(state.companyInfo),
    }
  }

}

const isCertHolder = (companyInfo) => {
  if(companyInfo){
    return companyInfo.labYN || companyInfo.ventureYn || companyInfo.innobizYn || companyInfo;
  }
}

const isTechHolder = (companyInfo) => {
  if(companyInfo){
    if(companyInfo.companyValue){
      return companyInfo.companyValue.patentCount > 0;
    }
  }
}

const SECTOR_INIT = ['C', 'E', 'F', 'G', 'J', 'M', 'N', 'P', "R"]
const isAllowedSector = (companyInfo) => {
  if(companyInfo){
    return SECTOR_INIT.includes(companyInfo.idscd.sub(0,1));
  }
}
