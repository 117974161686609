import sectorService from '~/service/commonTenbagger/sectorService';
import corpService from '~/service/commonTenbagger/corpService';

export const state = () => ({});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchSector(_, form) {
    return sectorService
      .getSector(form, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchSectorDetail(_, sectorNo) {
    return sectorService
      .getSectorDetail(sectorNo, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  addSector(_, payload) {
    return sectorService
      .addSector(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateSector(_, payload) {
    return sectorService
      .updateSector(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  deleteSector(_, sectorNo) {
    return sectorService
      .deleteSector(sectorNo, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateCorpRprSectors(_, payload) {
    return corpService
      .updateCorpRprSectors(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },
};
