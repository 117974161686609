import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/tenbagger/theme';

const getTheme = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.get(`${baseUrl}/list?${queryParam}`).then(response => {
    return response.data;
  });
};

const getThemeList = axios => {
  return axios.get(baseUrl).then(response => {
    return response.data;
  });
};

const getThemeDetail = (themeNo, axios) => {
  return axios.get(`${baseUrl}/${themeNo}`).then(response => {
    return response.data;
  });
};

const addTheme = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.post(`${baseUrl}?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateTheme = ({ themeNo, form }, axios) => {
  const queryParam = generateQueryParameter(form);
  return axios.put(`${baseUrl}/${themeNo}?${queryParam}`).then(response => {
    return response.data;
  });
};

const deleteTheme = (themeNo, axios) => {
  return axios.delete(`${baseUrl}/${themeNo}`).then(response => {
    return response.data;
  });
};

const updateDispTheme = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/display?${queryParam}`).then(response => {
    return response.data;
  });
};

export default {
  getTheme,
  getThemeList,
  getThemeDetail,
  addTheme,
  updateTheme,
  deleteTheme,
  updateDispTheme,
};
