import commonCodesService from '~/service/commonOpenIp/codesService';
import userService from '~/service/commonOpenIp/userService';

export const state = () => ({
  common: {},
  order: {
    RS000: [
      {
        code: 'RS001',
        codeName: '신청완료',
        codeDesc: '',
      },
      {
        code: 'RS002',
        codeName: '검토',
        codeDesc: '',
      },
      {
        code: 'RS003',
        codeName: '계약',
        codeDesc: '',
      },
      {
        code: 'RS004',
        codeName: '비용납부',
        codeDesc: '',
      },
      {
        code: 'RS005',
        codeName: '사용대기',
        codeDesc: '',
      },
      {
        code: 'RS006',
        codeName: '사용',
        codeDesc: '',
      },
    ],
  },
  etc: {
    grade: [
      { code: 9, codeName: 'AA', orderNo: 1 },
      { code: 8, codeName: 'A+', orderNo: 2 },
      { code: 7, codeName: 'A', orderNo: 3 },
      { code: 6, codeName: 'A-', orderNo: 4 },
      { code: 5, codeName: 'B+', orderNo: 5 },
      { code: 4, codeName: 'B', orderNo: 6 },
      { code: 3, codeName: 'B-', orderNo: 7 },
      { code: 2, codeName: 'C', orderNo: 8 },
    ],
    aplc: [
      { code: '서울대학교', codeName: '서울대학교', orderNo: 1 },
      { code: '한국전자통신연구원', codeName: '한국전자통신연구원', orderNo: 2 },
      { code: '한화', codeName: '한화', orderNo: 3 },
    ],
  },
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  async fetchCodes({ commit }) {
    let { result, data } = await commonCodesService.getCodes(this.$axios);
    if (result) {
      commit('UPDATE_STATE', { common: data.contents });
    } else {
      console.log(data.error.msg);
    }
  },

  fetchUserDetail(_, userNo) {
    return userService.getUserDetail(userNo, this.$axios).then(data => {
      return data;
    });
  },
};
