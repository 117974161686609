const url = '/api/auth';

const login = (body, axios) => {
  return axios.post(`${url}/login`, body);
};

const logout = (axios) => {
  return axios.get(`${url}/logout`);
};

const me = (axios) => {
  return axios.get(`${url}/user`);
}

export default {
  login,
  logout,
  me
};
