import Vue from 'vue'

const pageAuthCheck = {
  beforeMount() {
    if(this.roles){
      if(!this.$roleCheck(this.roles)){
        this.$router.push('/')
      }
    }
  }
}

Vue.mixin(pageAuthCheck)
