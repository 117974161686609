// export default function (context) {
//   // Add the userAgent property to the context

// }
const whiteList = [
  '/login',
  '/joinStep1',
  '/joinStep2',
  '/guide',
  '/sample/grid',
  '/sample/grid2',
  '/sample/page',
  '/customer/report',
  '/thinkpool'
];

export default function ({ store, route, redirect }) {
  const userId = store.state.authUser;
  if (!whiteList.includes(route.path)) {
    if (userId === null) {
      return redirect('/login');
    }
  }
}
