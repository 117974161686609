import themeService from '~/service/commonTenbagger/themeService';
import corpService from '~/service/commonTenbagger/corpService';

export const state = () => ({});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchTheme(_, form) {
    return themeService
      .getTheme(form, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchThemeDetail(_, themeNo) {
    return themeService
      .getThemeDetail(themeNo, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  addTheme(_, payload) {
    return themeService
      .addTheme(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateTheme(_, payload) {
    return themeService
      .updateTheme(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  deleteTheme(_, themeNo) {
    return themeService
      .deleteTheme(themeNo, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateCorpRprThemes(_, payload) {
    return corpService
      .updateCorpRprThemes(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  addCorpThemes(_, payload) {
    return corpService
      .addCorpThemes(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },
};
