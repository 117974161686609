import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/product';

const getProduct = (params, axios) => {
  const queryParam = generateQueryParameter(params);
  return axios.get(`${baseUrl}/prdcList?${queryParam}`).then(res => {
    return res.data;
  });
};

const getProductDetail = (prdc, axios) => {
  return axios.get(`${baseUrl}/prdcDetail/${prdc}`).then(res => {
    return res.data;
  });
};

const addProduct = (params, axios) => {
  const formData = new FormData();
  for (let key in params) {
    if (params[key] != null && params[key] != '') formData.append(key, params[key]);
  }
  return axios
    .post(`${baseUrl}/addProduct`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      return res.status;
    })
    .catch(err => {
      console.log(err);
    });
};

export default {
  getProduct,
  getProductDetail,
  addProduct,
};
