const baseUrl = '/api/allOk';
import {generateQueryParameter} from '../../util/HttpUtils'

// const getRequest = axios => {
//   return axios.get(`${baseUrl}/request`).then(res => {
//     return res.data;
//   });
// };

const postCustomer = (params, axios) => {
  const body = params;
  return axios.post(`${baseUrl}/customers`, body).then(res => {
    return res.data;
  });
};

const getCustomerDetail = (params, axios) => {
  const id = params.id;
  return axios.get(`${baseUrl}/customers/${id}`).then(res => {
    return res.data;
  });
}; //고객 상세

const getConsultantList = axios => {
  return axios.get(`${baseUrl}/consultants`).then(res => {
    return res.data;
  });
}; //컨설턴트 목록

const postConsultant = (params, axios) => {
  const id = params.id;
  const body = params.body;
  return axios.get(`${baseUrl}/customers/${id}/consultants`, body).then(res => {
    return res.data;
  });
}; //컨설턴트 할당

// const getConsultingList = (params, axios) => {
//   const id = params.id;
//   return axios.get(`${baseUrl}/customers/${id}/consulting`).then(res => {
//     return res.data;
//   });
// }; //대출 상담 내역 조회

// const postConsulting = (params, axios) => {
//   const id = params.id;
//   const body = {
//     customer_id: params.id,
//     consultingDt: params.date,
//     clientNm: params.customerInfo_name,
//     clientTel: params.customerInfo_phone,
//     clientEmail: params.customerInfo_email,
//     contentsCd: params.meeting,
//     stateCd: params.progress,
//     probCd: params.percentage,
//     gbnCd: params.division,
//     mngCd: params.management,
//     contents: params.content,
//   };
//   return axios.post(`${baseUrl}/customers/${id}/consulting`, body).then(res => {
//     return res.data;
//   });
// }; //대출 상담 등록

//코드 조회
const getCode = (axios) => {
  return axios.get(`${baseUrl}/codes`).then(res => {
    return res.data;
  });
};

const getConsultingDetail =(payload, axios)=>{
  return axios.get(`${baseUrl}/customers/${payload.customerId}/consulting/${payload.id}`).then(res => {
    return res.data;
  });
}

const registReport =(body, axios) => {
  return axios.post(`${baseUrl}/customers/${body.id}/report`, body).then(res=>{
    return res.data;
  })
}

const fetchLoadPrdc = (axios, form) => {
  return axios.get('/api/apiCmnAo/loan-prdc/match'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

const fetchFinance = (axios, form) => {
  return axios.get('/api/apiCmnAo/loan-prdc/finc/match'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

const fetchAnalyze = (axios, form) => {
  return axios.get('/api/apiCmnAo/loan-prdc/patn'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

const fetchLoanPrdcRcmd = (axios, form) => {
  return axios.get('/api/apiCmnAo/loan-prdc/rcmd'+'?'+generateQueryParameter(form))
    .then(result => result.data);
}

export default {
  //getRequest,
  getCustomerDetail,
  getConsultantList,
  postConsultant,
  //getConsultingList,
  //postConsulting,
  getCode,
  postCustomer,
  getConsultingDetail,
  registReport,
  fetchLoadPrdc,
  fetchFinance,
  fetchAnalyze,
  fetchLoanPrdcRcmd
};
