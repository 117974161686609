import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/commonOpenIp/patn';

const getPatn = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios
    .get(`${baseUrl}?${queryParam}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

const getPatnDetail = (patnNo, axios) => {
  return axios
    .get(`${baseUrl}/${patnNo}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log(err);
      return {
        result: false,
        data: {
          error: {
            cd: null,
            msg: '알수 없는 에러 발생',
          },
        },
      };
    });
};

export default {
  getPatn,
  getPatnDetail,
};
