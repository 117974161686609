import sectorService from '~/service/commonTenbagger/sectorService';
import themeService from '~/service/commonTenbagger/themeService';
import mtsService from '~/service/commonTenbagger/mtsService';

export const state = () => ({
  sectorList: [],
  themeList: [],
  issueSectorList: [],
  dispThemeList: [],
  defaultSector: null,
  defaultTheme: null,
});

export const mutations = {
  UPDATE_STATE(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key];
    });
  },
};

export const actions = {
  fetchSectorList({ commit }) {
    return sectorService
      .getSectorList(this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          let sectors = data.contents.map(el => ({ name: el.sectorName, value: el.sectorNo }));
          let issueSectorList = data.contents.filter(el => el.issueYn == 'Y').map(el => el.sectorNo);
          for (let i = 0; i < 7; i++) {
            if (!issueSectorList[i]) {
              issueSectorList.push(null);
            }
          }
          let defaultSector = data.contents.find(el => el.defaultYn == 'Y').sectorNo;
          commit('UPDATE_STATE', { sectorList: sectors });
          commit('UPDATE_STATE', { issueSectorList: issueSectorList });
          commit('UPDATE_STATE', { defaultSector: defaultSector });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  fetchThemeList({ commit }) {
    return themeService
      .getThemeList(this.$axios)
      .then(res => {
        let { result, data } = res;
        if (result) {
          let themes = data.contents.map(el => ({ name: el.themeName, value: el.themeNo }));
          let dispThemeList = data.contents.filter(el => el.displayYn == 'Y').map(el => el.themeNo);
          let defaultTheme = data.contents.find(el => el.defaultYn == 'Y').themeNo;
          commit('UPDATE_STATE', { themeList: themes });
          commit('UPDATE_STATE', { dispThemeList: dispThemeList });
          commit('UPDATE_STATE', { defaultTheme: defaultTheme });
        }
        return res;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  addIssueSectorStk(_, payload) {
    return sectorService
      .addIssueSectorStk(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateIssueSector(_, payload) {
    return sectorService
      .updateIssueSector(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateDispTheme(_, payload) {
    return themeService
      .updateDispTheme(payload, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateDefaultSector(_, sectorNo) {
    return mtsService
      .updateMtsDefaultSector(sectorNo, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },

  updateDefaultTheme(_, themeNo) {
    return mtsService
      .updateMtsDefaultTheme(themeNo, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },
};
