import { generateQueryParameter } from '~/util/HttpUtils';
const baseUrl = '/api/corp';

const getCorpList = (params, axios) => {
  const bizNo = params.bizNo;
  return axios.get(`${baseUrl}/${bizNo}`).then(response => {
    return response.data;
  });
};

const updateCorpRprSector = (query, axios) => {
  let queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/rpr-sector?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateCorpRprSectors = (query, axios) => {
  let queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/rpr-sectors?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateCorpRprTheme = (query, axios) => {
  let queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/rpr-theme?${queryParam}`).then(response => {
    return response.data;
  });
};

const updateCorpRprThemes = (query, axios) => {
  let queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/rpr-themes?${queryParam}`).then(response => {
    return response.data;
  });
};

const addCorpTheme = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/theme?${queryParam}`).then(response => {
    return response.data;
  });
};

const addCorpThemes = (query, axios) => {
  const queryParam = generateQueryParameter(query);
  return axios.put(`${baseUrl}/themes?${queryParam}`).then(response => {
    return response.data;
  });
};

export default {
  getCorpList,
  updateCorpRprSector,
  updateCorpRprSectors,
  updateCorpRprTheme,
  updateCorpRprThemes,
  addCorpTheme,
  addCorpThemes,
};
