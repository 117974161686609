import stkService from '~/service/commonTenbagger/stkService';

export const state = () => ({});

export const mutations = {};

export const actions = {
  fetchStk(_, form) {
    return stkService
      .getStk(form, this.$axios)
      .then(data => {
        return data;
      })
      .catch(err => {
        console.log(err);
        return {
          result: false,
          data: {
            error: {
              cd: null,
              msg: '알수 없는 에러 발생',
            },
          },
        };
      });
  },
};
